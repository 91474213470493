import React from "react"
import Layout from "../layouts/en"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import ListMainLayout from "../layouts/listmain"

const IndexPage = props => (
  <Layout location={props.location}>
    <SEO
      title="Home"
      description="Top page of Channel.241. You can find your favorite articles with various categories."
      lang="en"
    />
    <ListMainLayout data={props.data} langKey="en" />
    <form
      hidden
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      {/* <input type="hidden" name="form-name" value="contact" />
              <input type="hidden" name="bot-field" /> */}
      <input type="text" name="summary" />
      <input type="text" name="description" />
      <input type="text" name="name" />
      <input type="email" name="email" />
    </form>
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark(filter: { fields: { langKey: { eq: "en" } } }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            tags
            author
            price
          }
          fields {
            slug
            langKey
          }
          excerpt
        }
      }
    }
  }
`

export default IndexPage
